<script setup>
import { computed, toRef, ref, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import { useReservation } from 'src/composables/useReservation';
import { useAccount } from 'src/composables/useAccount';
import { useMediaQuery } from '@vueuse/core';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import MenuX from 'src/components/svgs/MenuX.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import AutoAppliedDiscountMiniBanner from '@/components/user/anytime/discounts/AutoAppliedDiscountMiniBanner.vue';
import BookingPricing from './BookingPricing.vue';
import isUndefined from 'lodash/isUndefined';
import { useCapability } from 'src/composables/useCapability';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { useUpdateReservationLocation } from 'src/queries/useUpdateReservation';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import { useReservationLocations } from 'src/queries/useReservationLocations';
import AlertIcon from 'src/components/svgs/AlertIcon.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { useProductsSearch } from 'src/queries/useProductsSearch';
import { useStore } from 'vuex';
import CaretViewDetails from 'src/components/svgs/CaretViewDetails.vue';
import {
  collapseSection,
  expandSection,
} from 'src/composables/useDrawerToggle';
import { toCurrency } from '@/lib/currency';
import { useIntegrations } from '@/composables/useIntegrations';
import SubscriptionPromoBanner from '@/components/shared/SubscriptionPromoBanner.vue';
import { useFlag } from '@/composables/useFlag';
import { useTiers } from '@/queries/tiers/useTiers';
import SoonaTransition from '@/components/ui_library/SoonaTransition.vue';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import { useOrderSalesTax } from '@/composables/sales_tax/useOrderSalesTax';
import EstimatedSalesTax from '@/components/sales_tax/EstimatedSalesTax.vue';

const props = defineProps({
  affixed: {
    default: true,
    type: Boolean,
  },
  calculateSalesTax: {
    required: false,
    type: Boolean,
    default: false,
  },
  customerBookingFlow: {
    required: false,
    type: Boolean,
    default: false,
  },
  toggleSidecart: {
    required: false,
    type: Function,
  },
  reservationId: String,
  showSubtotal: {
    type: Boolean,
    default: false,
  },
  showShippingLocation: {
    type: Boolean,
    default: false,
  },
  stepName: {
    type: String,
    required: false,
  },
  showCrewEstimatedText: {
    type: Boolean,
    default: false,
  },
  showSubscriptionPromoBanner: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:activePromo', 'return-to-previous-step']);

const route = useRoute();
const store = useStore();
const salesTaxStore = useSalesTaxStore();

const isWideScreen = useMediaQuery('(min-width: 60rem)');

const phoenixByoDdpFlag = useFlag('phoenix_byo_ddp');
const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');
const calculateSalesTax = computed(() => props.calculateSalesTax);

const {
  reservation,
  reservationAccountId,
  nameTruncated,
  isCompedDraft,
  isInStudio,
  isInternal,
  isSurprise,
  isHeadshotsForAll,
  isAnytime,
  isFree,
  isPack,
  estimatedPhotosNeeded,
  hasUnassignedLocation,
  lengthOfShoot,
  sceneTags,
  scheduledDateFormatted,
  scheduledTime,
  reservationLineItems: reservationLineItemsAll,
  shootType,
  videoType,
} = useReservation(toRef(props, 'reservationId'));

const reservationLineItems = computed(() =>
  reservationLineItemsAll.value?.filter(rli => rli.display !== false)
);

const { mutate, error: updateError } = useUpdateReservationLocation(
  toRef(props, 'reservationId')
);

const { data: locations, error: locationsError } = useReservationLocations(
  toRef(props, 'reservationId'),
  ref(['studio', 'remote'])
);

const { data: products } = useProductsSearch({
  locationId: computed(() => reservation.value?.location.id ?? null),
});

const accountId = computed(() => store.state.account.id);

const { hasShopifyIntegration } = useIntegrations(accountId);
const {
  subscription,
  isDigitalSubscriber,
  eligibleForPlatformSubscriptionTrial,
} = useAccount(accountId);

const product = computed(() => {
  return productId => products.value?.find(p => p.id == productId);
});

const itemsDetailsOpen = ref(false);
const bookingDetailsOpen = ref(true);

const productName = productName => {
  const name = productName || '';
  const lowercaseName = name.toLowerCase();

  if (lowercaseName === 'video') {
    return '1 video credit';
  } else if (lowercaseName === 'photo') {
    return '1 photo credit';
  } else {
    return name;
  }
};

const { data: tiers } = useTiers();
const tier_info = computed(() => {
  return tiers.value?.find(
    tier => tier.slug === reservation.value?.studio_access?.level
  );
});

const enforceMembershipSelection = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return !isDigitalSubscriber.value && !isHeadshotsForAll.value;
  }

  return false;
});

const subscriptionItemPrice = computed(() => {
  return tier_info.value?.product?.prices?.find(
    p =>
      p.recurring_interval === reservation.value?.studio_access?.billing_cycle
  );
});

const showDiscount = computed(() => {
  return !isUndefined(reservation.value?.down_payment_order?.discount);
});

const discount = computed(
  () => reservation.value?.down_payment_order?.discount
);
const discountCode = computed(() => {
  return discount.value?.code;
});

const discountAmount = computed(() => {
  return new Number(discount.value?.amount ?? 0).toFixed(2) ?? 0;
});

const preSalesTaxSubtotal = computed(() => {
  let reservationTotal = new Number(
    reservation.value?.down_payment_order_subtotal
  ).toFixed(2);

  if (
    enforceMembershipSelection.value &&
    (reservation.value?.studio_access?.level == 'tier-one' ||
      reservation.value?.studio_access?.level == 'tier-two') &&
    reservation.value?.studio_access?.billing_cycle != null &&
    reservation.value?.draft_type !== 'comped_draft'
  ) {
    let subscriptionTotal = subscriptionItemPrice.value?.unit_amount;

    let floatReservationTotal = parseFloat(reservationTotal);
    let floatSubscriptionTotal = parseFloat(subscriptionTotal);
    let result = floatReservationTotal + floatSubscriptionTotal;
    return result.toFixed(2);
  }

  return reservationTotal;
});

const preSalesTaxSubtotalWithDiscountOff = computed(() => {
  return preSalesTaxSubtotal.value - discountAmount.value;
});

const orderId = computed(() => reservation.value?.down_payment_order?.id);
const {
  isLoading: isCalculatingSalesTax,
  totalWithSurchargeDisplay,
  taxAmountExclusiveDisplay,
} = useOrderSalesTax({
  accountId: reservationAccountId,
  orderId,
  preSalesTaxSubtotal: preSalesTaxSubtotalWithDiscountOff,
  calculate: calculateSalesTax,
});

// preferred
const preferred = computed(() => reservation.value?.account?.preferred);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const isChangeLocationActive = ref(false);
const selectedLocation = ref(null);

const toggleChangeLocationModal = () => {
  isChangeLocationActive.value = !isChangeLocationActive.value;
};

const changeLocation = () => {
  const staffSelectedLocation = locations.value.find(
    x => x.location.id === selectedLocation.value
  );
  mutate(
    {
      locationId: staffSelectedLocation.location?.id,
    },
    {
      onSuccess: () => {
        toggleChangeLocationModal();
      },
    }
  );
};

const showSelectMembership = computed(() => {
  return (
    props?.stepName === 'down-payment' &&
    reservation.value?.steps?.find(x => x.slug === 'location')?.complete &&
    !isHeadshotsForAll.value
  );
});

const toggle = () => {
  if (props.toggleSidecart) {
    props.toggleSidecart();
  }
};

const selectableLocations = computed(() => {
  return (
    locations.value
      ?.filter(l => !l.incompatible)
      .map(l => ({
        label: l.location.display_name,
        value: l.location.id,
      })) || []
  );
});

const transferLocationFrom = computed(() => {
  return reservation.value?.account?.transfer_package
    ?.pending_transfer_destination;
});

const transferLocationTo = computed(() => {
  return (
    reservation.value?.override_location_name ||
    reservation.value?.location?.display_name
  );
});

const displayPackageTransferWarning = computed(() => {
  return (
    route.meta.validate_step === 'down-payment' &&
    transferLocationFrom.value &&
    !hasUnassignedLocation.value
  );
});

const sidecartContent = ref(null);

const showLocation = computed(() => {
  return (
    props?.stepName === 'location' ||
    reservation.value?.steps?.find(x => x.slug === 'location')?.complete ||
    isHeadshotsForAll ||
    isInternal
  );
});

const creditDescription = computed(() => {
  return reservationLineItems.value.find(
    rli => rli?.tag.title === 'Photo' || rli?.tag.title === 'Video'
  )?.tag.price_description;
});
const creditAmount = computed(() => {
  return parseInt(creditDescription.value?.match(/\d+/)[0] ?? 0);
});
const modelsAndUpgradesAmount = computed(() => {
  let discountValue = 0;
  if (discountAmount.value > 0) {
    discountValue = parseInt(discountAmount.value);
  } else {
    discountValue = 0;
  }

  if (shootType.value === 'photo' && preferred.value?.is_preferred) {
    return preSalesTaxSubtotal.value;
  }
  return preSalesTaxSubtotal.value - creditAmount.value + discountValue;
});

const canViewSubscriptionPromoBanner = computed(() => {
  return (
    eligibleForPlatformSubscriptionTrial.value &&
    !isFree.value &&
    (isAnytime.value || isInStudio.value)
  );
});

const isWithin72Hours = computed(() => {
  const now = new Date();
  const targetDate = new Date(reservation.value.start);
  const differenceInHours = (targetDate - now) / (1000 * 60 * 60);

  return Math.abs(differenceInHours) <= 72;
});

const shootLengthTooltipCopy = computed(() => {
  if (shootType.value === 'photo') {
    return 'we use your estimated number of photos to calculate the approximate length of your shoot. model & upgrade prices are dependent on shoot length.';
  } else if (shootType.value === 'video') {
    return 'we use your video type to determine the length of your shoot.';
  } else {
    return 'we use a variety of factors to determine your shoot length.';
  }
});

onUnmounted(() => {
  salesTaxStore.$reset();
});
</script>

<template>
  <aside
    class="booking-sidecart"
    :class="{
      'booking-sidecart--affixed': affixed,
      'booking-sidecart--white': showSubscriptionPromoBanner,
    }"
    aria-labelledby="booking-sidecart-heading"
  >
    <div class="booking-sidecart__inner">
      <header class="booking-sidecart__header">
        <h2
          id="booking-sidecart-heading"
          class="booking-sidecart__header-title"
        >
          Shoot Summary
        </h2>
        <button
          v-if="showSubscriptionPromoBanner"
          id="booking-sidecart-booking-details-toggle"
          class="u-button-reset u-body--regular booking-sidecart__details-toggle"
          :class="{
            'booking-sidecart__details-toggle--open':
              !showSubscriptionPromoBanner || bookingDetailsOpen,
          }"
          aria-controls="items-details"
          aria-label="toggle details panel"
          :aria-expanded="bookingDetailsOpen ? 'true' : 'false'"
          @click="
            bookingDetailsOpen
              ? collapseSection($refs.sidecartSummary, () => {
                  bookingDetailsOpen = false;
                })
              : ((bookingDetailsOpen = true),
                expandSection($refs.sidecartSummary))
          "
        >
          view details
          <CaretViewDetails aria-hidden="true" fill="#71727f" />
        </button>
        <button
          v-if="affixed && !isWideScreen"
          type="button"
          class="u-button-reset booking-sidecart__close"
          @click="toggle"
        >
          <MenuX />
        </button>
      </header>
      <div
        ref="sidecartContent"
        class="booking-sidecart__content"
        :class="{
          'booking-sidecart__content--has-transfer': transferLocationFrom,
        }"
      >
        <div
          ref="sidecartSummary"
          class="booking-sidecart__summary u-drawer"
          :class="{ 'u-drawer--open': bookingDetailsOpen }"
        >
          <strong v-if="nameTruncated" class="booking-sidecart__shoot-title">
            {{ nameTruncated }}
          </strong>
          <ul v-if="reservation" class="booking-sidecart__details">
            <template v-if="showLocation">
              <li
                v-if="isInStudio || isSurprise || isHeadshotsForAll"
                class="booking-sidecart__detail"
              >
                <SoonaIcon name="shop" />
                in-studio shoot
                <SoonaTooltip>
                  <template
                    #default="{
                      setRef,
                      mouseenter,
                      focus,
                      mouseleave,
                      blur,
                      ariaDescribedby,
                    }"
                  >
                    <SoonaIcon
                      :ref="setRef"
                      class="tooltip"
                      name="circle-question"
                      size="medium"
                      :aria-describedby="ariaDescribedby"
                      @mouseenter="mouseenter"
                      @focus="focus"
                      @mouseleave="mouseleave"
                      @blur="blur"
                    />
                  </template>
                  <template #tooltip-content>
                    you’ll be attending your shoot in person and will be able to
                    collaborate with our team on set.
                  </template>
                </SoonaTooltip>
              </li>
              <li v-if="isInternal" class="booking-sidecart__detail">
                <SoonaIcon name="shop" />
                internal shoot
              </li>
              <li v-else-if="isAnytime" class="booking-sidecart__detail">
                <SoonaIcon name="monitor" />
                virtual shoot
                <SoonaTooltip>
                  <template
                    #default="{
                      setRef,
                      mouseenter,
                      focus,
                      mouseleave,
                      blur,
                      ariaDescribedby,
                    }"
                  >
                    <SoonaIcon
                      :ref="setRef"
                      class="tooltip"
                      name="circle-question"
                      size="medium"
                      :aria-describedby="ariaDescribedby"
                      @mouseenter="mouseenter"
                      @focus="focus"
                      @mouseleave="mouseleave"
                      @blur="blur"
                    />
                  </template>
                  <template #tooltip-content>
                    you’ll be attending your shoot virtually and providing
                    feedback to our team via chat as content flows into your
                    gallery in real-time.
                  </template>
                </SoonaTooltip>
              </li>
            </template>
            <li
              v-if="
                (!isAnytime || isInternal) &&
                reservation.scheduled_reservation_date
              "
              class="booking-sidecart__detail"
            >
              <SoonaIcon title="date and time" name="calendar-solid" />
              <time>
                {{ scheduledDateFormatted.toLowerCase() }}
                <span class="booking-sidecart__detail-pipe"> | </span>
                {{ scheduledTime }}
              </time>
            </li>
            <li
              v-if="
                (isInStudio || isSurprise || isHeadshotsForAll || isInternal) &&
                (reservation.override_location_name ||
                  (reservation.location && reservation.location.name))
              "
              class="booking-sidecart__detail"
            >
              <SoonaIcon name="location-pin" />
              <dl>
                <dt>location:</dt>
                <dd>
                  <address
                    v-if="
                      reservation?.booking_flow === 'headshots_for_all_event'
                    "
                  >
                    <b>soona</b>
                  </address>
                  <address v-else>
                    <b>
                      {{
                        reservation.override_location_name ||
                        'soona ' + reservation.location.display_name
                      }}
                    </b>
                    <template
                      v-if="
                        reservation.override_location_name &&
                        reservation.override_location_name.length > 0
                      "
                    >
                      <br />{{ reservation.override_location_address }}
                    </template>
                    <template v-else>
                      <br />{{ reservation.location.address1 }}<br />
                      {{ reservation.location.city }},
                      {{ reservation.location.state }}
                      {{ reservation.location.zip }}
                    </template>
                  </address>
                </dd>
              </dl>
            </li>
            <li
              v-if="
                isAnytime &&
                (reservation.override_location_name ||
                  (reservation.location && reservation.location.name)) &&
                showShippingLocation
              "
              class="booking-sidecart__detail"
            >
              <SoonaIcon name="truck" />
              <dl>
                <dt>shipping location:</dt>
                <dd>
                  <b>
                    {{
                      reservation.override_location_name ||
                      reservation.location.display_name
                    }}
                  </b>
                </dd>
              </dl>
              <SoonaTooltip>
                <template
                  #default="{
                    setRef,
                    mouseenter,
                    focus,
                    mouseleave,
                    blur,
                    ariaDescribedby,
                  }"
                >
                  <SoonaIcon
                    :ref="setRef"
                    class="tooltip"
                    name="circle-question"
                    size="medium"
                    :aria-describedby="ariaDescribedby"
                    @mouseenter="mouseenter"
                    @focus="focus"
                    @mouseleave="mouseleave"
                    @blur="blur"
                  />
                </template>
                <template #tooltip-content>
                  we need your products to schedule your shoot. you’ll receive
                  the mailing address after you complete your down payment
                  today.
                </template>
              </SoonaTooltip>
            </li>
            <li
              v-if="isAnytime && isFtSoonaStaff && showShippingLocation"
              class="booking-sidecart__detail"
            >
              <button
                class="u-button-reset booking-sidecart__change-location"
                @click="toggleChangeLocationModal"
              >
                change shipping location
              </button>
              <SoonaDialog
                v-if="isChangeLocationActive"
                @close="toggleChangeLocationModal"
              >
                <template #heading>change location</template>
                <SoonaSelect
                  v-model:model-value="selectedLocation"
                  :placeholder="reservation.location.display_name"
                  :options="selectableLocations"
                >
                  <template #label>location</template>
                </SoonaSelect>
                <SoonaError v-if="locationsError">
                  Unable to load location options
                </SoonaError>
                <SoonaError v-if="updateError">
                  Error updating booking location
                </SoonaError>
                <template #footer="{ close }">
                  <SoonaButton variation="tertiary" @on-click="close">
                    cancel
                  </SoonaButton>
                  <SoonaButton
                    :disabled="!selectedLocation"
                    data-cypress="button-dialog-confirm"
                    @on-click="changeLocation"
                  >
                    ok
                  </SoonaButton>
                </template>
              </SoonaDialog>
            </li>
            <li
              v-if="
                (shootType === 'photo' && preferred?.is_preferred) ||
                (shootType === 'video' && videoType)
              "
              class="booking-sidecart__detail booking-sidecart__detail--align-center"
            >
              <dl>
                <dt>type:</dt>
                <dd class="booking-sidecart__pill">
                  {{ shootType === 'video' ? videoType : shootType }}
                </dd>
              </dl>
            </li>
            <li
              v-if="sceneTags.length >= 1"
              class="booking-sidecart__detail booking-sidecart__detail--align-center"
            >
              <dl>
                <dt>scenes:</dt>
                <dd
                  v-for="tag in sceneTags"
                  :key="tag.title"
                  class="booking-sidecart__pill"
                >
                  {{ tag.title }}
                </dd>
              </dl>
            </li>
            <li
              v-if="!isPack && estimatedPhotosNeeded"
              class="booking-sidecart__detail booking-sidecart__detail--align-center"
            >
              <dl>
                <dt>estimated photos needed:</dt>
                <dd class="booking-sidecart__pill">
                  {{ estimatedPhotosNeeded }}
                </dd>
              </dl>
            </li>
            <li
              v-if="
                lengthOfShoot &&
                (estimatedPhotosNeeded ||
                  (shootType === 'video' && !isPack) ||
                  isHeadshotsForAll)
              "
              class="booking-sidecart__detail booking-sidecart__detail--length"
            >
              <dl>
                <dt>
                  <em
                    :class="{
                      'booking-sidecart__detail--equals': estimatedPhotosNeeded,
                    }"
                    >length of shoot:
                  </em>
                </dt>
                <dd>
                  <b>{{ lengthOfShoot.display }}</b>
                </dd>
              </dl>
              <SoonaTooltip>
                <template
                  #default="{
                    setRef,
                    mouseenter,
                    focus,
                    mouseleave,
                    blur,
                    ariaDescribedby,
                  }"
                >
                  <SoonaIcon
                    :ref="setRef"
                    class="tooltip"
                    name="circle-question"
                    size="medium"
                    :aria-describedby="ariaDescribedby"
                    @mouseenter="mouseenter"
                    @focus="focus"
                    @mouseleave="mouseleave"
                    @blur="blur"
                  />
                </template>
                <template #tooltip-content>
                  {{ shootLengthTooltipCopy }}
                </template>
              </SoonaTooltip>
            </li>
          </ul>
        </div>
        <AutoAppliedDiscountMiniBanner
          class="booking-sidecart__auto-apply-banner"
          :account-id="reservation?.account_id"
          :preferred="preferred"
          :reservation-id="reservationId"
          :tagline="discount?.auto_apply_tagline"
        />
        <div
          v-if="displayPackageTransferWarning"
          class="booking-sidecart__transfer"
        >
          <AlertIcon
            class="booking-sidecart__transfer-icon"
            aria-label="Alert"
          />
          <p>
            <strong>sending new items?</strong><br />
            ship to our
            {{ transferLocationTo }}
            location. our crew will transfer your existing inventory from
            {{ transferLocationFrom }}
            to
            {{ transferLocationTo }}.
            <SoonaTooltip>
              <template
                #default="{
                  setRef,
                  mouseenter,
                  focus,
                  mouseleave,
                  blur,
                  ariaDescribedby,
                }"
              >
                <SoonaIcon
                  :ref="setRef"
                  class="tooltip"
                  name="circle-question"
                  size="medium"
                  :aria-describedby="ariaDescribedby"
                  @mouseenter="mouseenter"
                  @focus="focus"
                  @mouseleave="mouseleave"
                  @blur="blur"
                />
              </template>
              <template #tooltip-content>
                our {{ transferLocationFrom }} location doesn’t support every
                setting yet. we’ll get you set up somewhere that does.
              </template>
            </SoonaTooltip>
          </p>
        </div>
        <BookingPricing
          :affixed="affixed"
          :reservation-id="reservationId"
          :reservation-line-items="reservationLineItems"
        />
        <slot name="actions" />
      </div>
      <SubscriptionPromoBanner
        v-if="canViewSubscriptionPromoBanner && showSubscriptionPromoBanner"
        @update:active-promo="value => emit('update:activePromo', value)"
      />
      <div class="booking-sidecart__membership-banner">
        <SoonaTransition name="fade-up">
          <div
            v-if="
              enforceMembershipSelection &&
              reservation.studio_access?.level == 'day-pass' &&
              showSelectMembership
            "
            class="membership-banner-content membership-banner-content--studio-pass"
            @click="emit('return-to-previous-step')"
          >
            <div>
              <p class="u-label--heavy">
                skip the studio pass charge by becoming a member
              </p>
              <p class="u-label--regular">
                members save an average of <b>$1,596</b> per year.
              </p>
            </div>

            <SoonaIcon name="arrow-right" />
          </div>
          <div
            v-else-if="enforceMembershipSelection && showSelectMembership"
            class="membership-banner-content"
          >
            <p>Members <b>save $1,596 annually</b> on average.</p>
          </div>
          <div
            v-else-if="isDigitalSubscriber && phoenixStudioRentalFeeFlag"
            :class="{
              'membership-banner-content--tier-one':
                subscription.tier.slug === 'tier-one',
              'membership-banner-content--tier-two':
                subscription.tier.slug === 'tier-two',
              'membership-banner-content--tier-three':
                subscription.tier.slug === 'tier-three',
            }"
            class="membership-banner-content"
          >
            <p>
              Thanks for being a soona
              <b class="membership-banner-subscription-name">{{
                subscription.tier.name
              }}</b>
              member!
            </p>
          </div>
        </SoonaTransition>
      </div>

      <footer
        class="booking-sidecart__footer"
        :class="[
          canViewSubscriptionPromoBanner &&
            showSubscriptionPromoBanner &&
            'booking-sidecart__footer--borderless',
        ]"
      >
        <div v-if="isCompedDraft">
          <p
            v-if="showDiscount && !phoenixByoDdpFlag"
            class="booking-sidecart__amount-promo"
          >
            <span>
              Promo code: <b>{{ discountCode }}</b>
            </span>
            <strong class="u-subheader--heavy"> -{{ discountAmount }} </strong>
          </p>
          <p class="booking-sidecart__total-cost">
            Total cost:
            <span class="booking-sidecart__total-cost--value">
              {{ toCurrency(preSalesTaxSubtotal) }}
            </span>
          </p>
          <p class="booking-sidecart__comped-amount">
            Comped amount:
            <span class="booking-sidecart__comped-amount--value">
              -{{ toCurrency(preSalesTaxSubtotal) }}
            </span>
          </p>
          <p class="booking-sidecart__amount-due">
            Amount due today:
            <strong class="u-subheader--heavy">$0</strong>
          </p>
        </div>
        <template
          v-else-if="
            phoenixByoDdpFlag &&
            !isPack &&
            !hasShopifyIntegration &&
            !isHeadshotsForAll &&
            customerBookingFlow
          "
        >
          <template v-if="isWithin72Hours">
            <p class="booking-sidecart__charge-explanation">
              we’ll charge your down payment and any models or upgrades today
              since your shoot is happening in less than 72 hours.
            </p>
            <p
              class="booking-sidecart__amount-due booking-sidecart__amount-due-delayed"
            >
              Due before shoot:
              <strong class="u-subheader--regular">
                {{ toCurrency(preSalesTaxSubtotal) }}
              </strong>
            </p>
            <ul class="booking-sidecart__under-72">
              <li
                v-if="!(shootType === 'photo' && preferred?.is_preferred)"
                class="u-body--regular booking-sidecart__cost-breakdown booking-sidecart__cost-breakdown--large"
              >
                1 {{ shootType }} credit:
                <strong> {{ toCurrency(creditAmount) }} </strong>
              </li>
              <li
                class="u-body--regular booking-sidecart__cost-breakdown booking-sidecart__cost-breakdown--large"
              >
                models & upgrades:
                <strong> {{ toCurrency(modelsAndUpgradesAmount) }} </strong>
              </li>
            </ul>
            <p v-if="showDiscount" class="booking-sidecart__amount-promo">
              <span>
                Promo code: <b>{{ discountCode }}</b>
              </span>
              <strong class="u-subheader--heavy">
                -${{ discountAmount }}
              </strong>
            </p>
            <p class="booking-sidecart__amount-due total-cost-divider">
              Due today:
              <strong class="u-subheader--heavy">
                {{ toCurrency(preSalesTaxSubtotal) }}
              </strong>
            </p>
          </template>
          <template v-else>
            <p class="booking-sidecart__charge-explanation">
              we’ll charge your down payment and any models or upgrades 3 days
              before your shoot starts.
            </p>
            <p
              class="booking-sidecart__amount-due booking-sidecart__amount-due-delayed"
            >
              Due before shoot:
              <strong class="u-body--regular">
                {{ toCurrency(preSalesTaxSubtotal) }}
              </strong>
            </p>
            <div class="booking-sidecart__items-title-wrapper">
              <button
                id="booking-sidecart-items-details-title"
                class="u-button-reset u-body--regular booking-sidecart__details-toggle"
                :class="{
                  'booking-sidecart__details-toggle--open': itemsDetailsOpen,
                }"
                aria-controls="items-details"
                aria-label="toggle details panel"
                :aria-expanded="itemsDetailsOpen ? 'true' : 'false'"
                @click="
                  itemsDetailsOpen
                    ? collapseSection($refs.itemsDetails, () => {
                        itemsDetailsOpen = false;
                      })
                    : ((itemsDetailsOpen = true),
                      expandSection($refs.itemsDetails))
                "
              >
                view details
                <CaretViewDetails aria-hidden="true" fill="#71727f" />
              </button>
              <ul
                id="booking-sidecart-items-details"
                ref="itemsDetails"
                class="u-drawer"
                :class="{ 'u-drawer--open': itemsDetailsOpen }"
                aria-labelledby="booking-sidecart-items-details-title"
              >
                <li
                  v-for="rli in reservationLineItems"
                  :key="rli.id"
                  class="u-label--regular booking-sidecart__cost-breakdown"
                >
                  {{ productName(product(rli.product_id)?.name) }}
                  <em>
                    <template v-if="rli.quantity > 1">
                      <small class="booking-sidecart__cost-math">
                        ({{ rli.quantity }} ×
                        <s v-if="rli.effective_percent_discount">
                          {{ toCurrency(product(rli.product_id)?.rate) }}
                        </s>
                        {{
                          toCurrency(
                            product(rli.product_id)?.rate -
                              rli.effective_percent_discount / rli.quantity
                          )
                        }})
                      </small>
                      {{
                        toCurrency(
                          product(rli.product_id)?.rate * rli.quantity -
                            rli.effective_percent_discount
                        )
                      }}
                    </template>
                    <template v-else-if="!isFree">
                      <s v-if="rli.effective_percent_discount">
                        {{ toCurrency(product(rli.product_id)?.rate) }}
                      </s>
                      {{
                        toCurrency(
                          product(rli.product_id)?.rate * rli.quantity -
                            rli.effective_percent_discount
                        )
                      }}
                    </template>
                    <template v-else> FREE</template>
                  </em>
                </li>
              </ul>
            </div>
            <p v-if="showDiscount" class="booking-sidecart__amount-promo">
              <span>
                Promo code: <b>{{ discountCode }}</b>
              </span>
              <strong class="u-subheader--heavy">
                -{{ discountAmount }}
              </strong>
            </p>
            <p class="booking-sidecart__amount-due total-cost-divider">
              Due today:
              <strong class="u-subheader--heavy"> $0 </strong>
            </p>
          </template>
        </template>
        <template v-else>
          <!-- subtotal -->
          <p
            v-if="calculateSalesTax || showSubtotal"
            class="booking-sidecart__subtotal"
          >
            Subtotal:
            <span>
              {{ isFree ? '$0' : toCurrency(preSalesTaxSubtotal) }}
            </span>
          </p>
          <!-- discount -->
          <p
            v-if="showDiscount && !phoenixByoDdpFlag"
            class="booking-sidecart__amount-promo"
          >
            <span>
              Promo code: <b>{{ discountCode }}</b>
            </span>
            <strong class="u-subheader--heavy"> -${{ discountAmount }} </strong>
          </p>
          <!-- surcharge -->
          <p
            v-if="calculateSalesTax || showCrewEstimatedText"
            class="booking-sidecart__surcharge"
          >
            <EstimatedSalesTax :is-capitalized="true" />
            <span v-if="showCrewEstimatedText">calculated on checkout</span>
            <span v-else-if="isCalculatingSalesTax"> calculating... </span>
            <span v-else>
              {{ taxAmountExclusiveDisplay }}
            </span>
          </p>
          <!-- subtotal -->
          <p class="booking-sidecart__amount-due">
            Amount due today:
            <strong class="u-subheader--heavy">
              {{ totalWithSurchargeDisplay }}
            </strong>
          </p>
        </template>
      </footer>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-right {
  to {
    transform: translateX(100%);
  }
}

.booking-sidecart {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  &__inner {
    display: flex;
    flex-direction: column;
    background-color: variables.$white-default;
    width: 100%;
  }

  &--affixed {
    display: none;
  }

  &--affixed .booking-sidecart__inner {
    width: 94.4%;
    max-width: 27.625rem;
    animation: 0.3s ease-out both slide-left;
  }

  &--affixed[class*='closing'] .booking-sidecart__inner {
    animation: 0.3s ease-out both slide-right;
  }

  &--white {
    .booking-sidecart__header {
      background-color: transparent;
    }

    .booking-sidecart__summary {
      background-color: transparent;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: variables.$periwink-blue-10;
    padding: 1rem 1rem 0.875rem;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 1rem;
      right: 1rem;
      bottom: 0;
      height: 1px;
      background-color: variables.$gray-40;

      .booking-sidecart--affixed & {
        left: 0;
        right: 0;
      }
    }
  }

  &__items-title-wrapper {
    margin-bottom: 1rem;
  }

  &__details-toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: variables.$gray-60;

    > svg {
      display: block;
      margin-left: 0.25rem;
    }

    &--open {
      > svg {
        transform: rotate(180deg);
      }
    }
  }

  &__close {
    svg {
      display: block;
      width: 1.375rem;
      height: 1.375rem;

      &:deep(g) {
        transition: fill 0.1s ease-out;
        fill: variables.$black-default;
      }
    }

    &:hover,
    &:focus-visible {
      &:deep(g) {
        fill: variables.$black-default;
      }
    }
  }

  &__header-title {
    @include variables_fonts.u-small--heavy;

    color: variables.$black-default;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }

  &__content {
    flex-grow: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0 1rem;

    &--has-transfer {
      margin-bottom: -0.0625rem;
      z-index: 0;
    }
  }

  &__membership-banner {
    flex: none;
    overflow: hidden;

    .membership-banner-content {
      margin: 0 1rem;
      text-align: center;
      padding: 0.75rem 1rem;
      border-radius: 10px 10px 0 0;
      color: variables.$white-default;
      background: variables.$gradient-red-to-blue-periwink;

      .membership-banner-subscription-name {
        text-transform: capitalize;
      }

      &--studio-pass {
        display: flex;
        cursor: pointer;
        text-align: left;
        align-items: center;
        justify-content: space-between;

        p:last-of-type {
          margin-top: 0.25rem;
          color: variables.$gray-20;
        }
      }

      &--tier-one {
        background: variables.$gradient-periwink-membership;
      }

      &--tier-three {
        background: variables.$gradient-enterprise-sunrise;
      }

      &--tier-one,
      &--tier-two,
      &--tier-three {
        padding: 0.25rem;
      }
    }
  }

  &__summary {
    background-color: variables.$periwink-blue-10;
    padding: 0 1rem;
    margin: 0 -1rem;
  }

  &__shoot-title {
    display: block;
    color: variables.$black-default;
    margin-bottom: 0.875rem;
    padding-top: 0.875rem;
  }

  &__details {
    display: block;

    &:not(:empty) {
      padding-bottom: 1rem;

      &:first-child {
        padding-top: 0.875rem;
      }
    }
  }

  .tooltip {
    align-self: center;
    color: variables.$gray-60;
    margin-left: 0.25rem;
  }

  &__detail {
    display: flex;
    align-items: flex-start;
    color: variables.$black-default;

    &:not(:last-child) {
      margin-bottom: 0.875rem;
    }

    > svg {
      display: block;
      margin-right: 0.5rem;
      flex: 0 0 1.5rem;
    }

    > dl {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    address {
      font-style: normal;
    }

    &--align-center > dl {
      align-items: center;
    }

    &--length {
      justify-content: flex-end;

      > dl > dt > em::before {
        content: '=';
      }
    }
  }

  &__detail-pipe {
    @include variables_fonts.u-subheader--heavy;

    color: variables.$friendly-red-50;
    margin-right: 0;
  }

  &__change-location {
    text-decoration: underline;
    font-size: 1rem;
    line-height: 1.5;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__pill {
    @include variables_fonts.u-label--heavy;

    background-color: variables.$white-default;
    padding: 0.375rem 0.75rem;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 3.125rem;
  }

  &__transfer {
    @include variables_fonts.u-body--regular;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: variables.$tangerine-10;
    border: solid variables.$tangerine-40;
    border-width: 1px 0;
    margin: 0 -1rem;
    padding: 1rem;
    overflow: visible;

    &-icon {
      flex: 0 0 1.25rem;
      margin: 0.125rem 0.5rem 0 0;
    }

    .tooltip {
      display: inline-flex;
      vertical-align: text-bottom;
    }
  }

  &__footer {
    border-top: 1px solid variables.$gray-30;
    padding-top: 0.5rem;
    background-color: variables.$white-default;
    margin: 0 1rem 1rem;

    &--borderless {
      border-top: 0;
    }
  }

  &__amount-due,
  &__amount-promo,
  &__comped-amount,
  &__total-cost,
  &__subtotal,
  &__surcharge {
    color: variables.$black-default;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    strong {
      color: variables.$black-default;
    }
  }

  &__surcharge {
    &-text {
      display: flex;
      gap: 0.25rem;
    }
  }

  &__amount-due,
  &__comped-amount,
  &__total-cost {
    @include variables_fonts.u-button--large-caps;
  }

  &__charge-explanation {
    margin-bottom: 0.75rem;
  }

  &__under-72 {
    margin-bottom: 0.75rem;
  }

  &__cost-breakdown {
    color: variables.$gray-60;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 0.5rem;

    strong {
      font-weight: 400;
    }

    em {
      font-style: normal;
    }

    &--large {
      color: variables.$black-default;
      margin-top: 0.75rem;
    }
  }

  &__cost-math {
    margin-right: 0.25rem;
  }

  &__amount-due-delayed {
    margin-bottom: 0.5rem;
  }

  .total-cost-divider {
    padding-top: 0.75rem;
    border-top: 0.0625rem solid variables.$gray-30;
  }

  &__comped-amount,
  &__total-cost {
    color: variables.$gray-60;

    &--value {
      @include variables_fonts.u-subheader--heavy;
      color: variables.$gray-60;
    }
  }

  &__amount-promo {
    b {
      font-weight: 800;
    }

    strong {
      color: variables.$friendly-red-70;
    }
  }

  &__estimate-btn {
    @include variables_fonts.u-label--heavy;

    display: flex;
    align-items: center;
    text-transform: lowercase;
    margin-top: 0.75rem;
    border: 1px solid variables.$gray-30;
    border-radius: 0.3125rem;
    padding: 0.5rem 1rem 0.5rem 0.75rem;
    transition: background-color 0.1s ease-out;

    svg {
      flex: 0 0 1rem;
      margin-right: 0.25rem;
    }

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-20;
    }
  }

  // discount
  &__auto-apply-banner {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  @media (min-width: 48rem) {
    &:not(.booking-sidecart--affixed) .booking-sidecart {
      &__header {
        padding-top: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        &::after {
          left: 1.5rem;
          right: 1.5rem;
        }
      }

      &__summary {
        padding: 0 1.5rem;
        margin: 0 -1.5rem;
      }

      &__shoot-title {
        padding-top: 1.5rem;
      }

      &__transfer {
        margin: 0 -1.5rem;
        padding: 1rem 1.5rem;
      }

      &__details:not(:empty) {
        padding-bottom: 1.5rem;

        &:first-child {
          padding-top: 1.5rem;
        }
      }

      &__footer {
        margin-bottom: 1.5rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }

      &__content {
        padding: 0 1.5rem;
      }

      &__auto-apply-banner {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }
    }
  }

  @media (min-width: 60rem) {
    &--affixed {
      display: flex;
    }

    &--affixed .booking-sidecart__inner {
      width: 100%;
      animation: none !important;
    }

    &--affixed .booking-sidecart__header::after {
      left: 1rem;
      right: 1rem;
    }
  }

  @media (min-width: 29.3125rem) and (max-width: 59.9375rem) {
    &--affixed .booking-sidecart {
      &__header {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }

      &__content {
        padding: 0 1.5rem;
      }

      &__auto-apply-banner {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }

      &__summary,
      &__transfer {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin: 0 -1.5rem;
      }

      &__footer {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }
    }
  }

  @media (min-width: 75rem) {
    &--affixed .booking-sidecart {
      &__header {
        padding-top: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        &::after {
          left: 1.5rem;
          right: 1.5rem;
        }
      }

      &__summary {
        padding: 0 1.5rem;
        margin: 0 -1.5rem;
      }

      &__shoot-title {
        padding-top: 1.5rem;
      }

      &__transfer {
        padding: 1rem 1.5rem;
        margin: 0 -1.5rem;
      }

      &__details:not(:empty) {
        padding-bottom: 1.5rem;

        &:first-child {
          padding-top: 1.5rem;
        }
      }

      &__footer {
        margin-bottom: 1.5rem;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
      }

      &__content {
        padding: 0 1.5rem;
      }

      &__auto-apply-banner {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
      }

      &__membership-banner {
        .membership-banner-content {
          margin: 0 2.5rem;
        }
      }
    }
  }
}
</style>
