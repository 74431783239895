<script setup>
import { computed, ref } from 'vue';
import { toCurrency } from '@/lib/currency';
import { useCapabilities } from '@/composables/useCapabilities';
import { useRetainer } from '@/queries/retainers/useRetainer';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const props = defineProps({
  accountBalance: {
    default: null,
    type: Number,
  },
  accountId: {
    required: true,
    type: String,
  },
});

const accountId = computed(() => props.accountId);

const capabilitiesRef = ref([
  { capability: 'withdraw_from_retainer' },
  { capability: 'fund_retainer' },
]);

const capabilities = useCapabilities(capabilitiesRef);

const { data: retainer } = useRetainer({ accountId });

const canPerformActions = computed(
  () =>
    capabilities.value?.fund_retainer?.hasCapability ||
    capabilities.value?.withdraw_from_retainer?.hasCapability
);
</script>

<template>
  <article class="account-balance">
    <h2 class="u-subheader--heavy">Account balance</h2>
    <p class="u-display--heavy">
      {{ toCurrency(retainer?.current_amount ?? 0) }}
    </p>
    <div v-if="canPerformActions" class="account-balance__action-btns">
      <SoonaButton
        v-if="capabilities?.fund_retainer?.hasCapability"
        element="router-link"
        size="medium"
        :to="`/account/${accountId}/retainer/add`"
        variation="secondary-gray"
      >
        Add balance
      </SoonaButton>
      <!-- payments_todo: make this do something -->
      <SoonaButton
        v-if="capabilities?.withdraw_from_retainer?.hasCapability"
        variation="secondary-gray"
        size="medium"
      >
        Deduct from balance
      </SoonaButton>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.account-balance {
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  padding: 1.5rem;
  width: 100%;

  &__action-btns {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
  }
}
</style>
