<script setup>
import { computed, ref } from 'vue';
import { toCurrency } from '@/lib/currency';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  transactions: {
    required: true,
    type: Array,
  },
});

const emit = defineEmits(['onSort']);

const router = useRouter();

const accountId = computed(() => props.accountId);

const currentSort = ref({
  column: 'date',
  order: 'desc',
});

const handleSort = column => {
  if (currentSort.value.column === column) {
    currentSort.value.order =
      currentSort.value.order === 'asc' ? 'desc' : 'asc';
  } else {
    currentSort.value.column = column;
    currentSort.value.order = 'asc';
  }
  emit('onSort', currentSort.value);
};

const handleKeydown = (e, column) => {
  if (e.key === 'Enter') handleSort(column);
};

const handleRowAction = (id, e = null) => {
  if (!e || e?.key === 'Enter') {
    router.push({
      name: 'order-view',
      params: { accountId: accountId.value, orderId: id },
    });
  }
};
</script>

<template>
  <div class="contract-order-history-table">
    <table>
      <thead>
        <tr>
          <th
            tabindex="0"
            @click="() => handleSort('date')"
            @keydown="e => handleKeydown(e, 'date')"
          >
            <span
              class="contract-order-history-table__sort"
              :class="`contract-order-history-table__sort--${
                currentSort.column === 'date' ? currentSort.order : 'inactive'
              }`"
            >
              Date
              <SoonaIcon name="arrow-up" size="small" />
            </span>
          </th>
          <th
            tabindex="0"
            @click="() => handleSort('charged_by')"
            @keydown="e => handleKeydown(e, 'charged_by')"
          >
            <span
              class="contract-order-history-table__sort"
              :class="`contract-order-history-table__sort--${
                currentSort.column === 'charged_by'
                  ? currentSort.order
                  : 'inactive'
              }`"
            >
              Charged by
              <SoonaIcon name="arrow-up" size="small" />
            </span>
          </th>
          <th
            tabindex="0"
            @click="() => handleSort('type')"
            @keydown="e => handleKeydown(e, 'type')"
          >
            <span
              class="contract-order-history-table__sort"
              :class="`contract-order-history-table__sort--${
                currentSort.column === 'type' ? currentSort.order : 'inactive'
              }`"
            >
              Content type
              <SoonaIcon name="arrow-up" size="small" />
            </span>
          </th>
          <th
            tabindex="0"
            @click="() => handleSort('service')"
            @keydown="e => handleKeydown(e, 'service')"
          >
            <span
              class="contract-order-history-table__sort"
              :class="`contract-order-history-table__sort--${
                currentSort.column === 'service'
                  ? currentSort.order
                  : 'inactive'
              }`"
            >
              Service
              <SoonaIcon name="arrow-up" size="small" />
            </span>
          </th>
          <th
            tabindex="0"
            @click="() => handleSort('quantity')"
            @keydown="e => handleKeydown(e, 'quantity')"
          >
            <span
              class="contract-order-history-table__sort"
              :class="`contract-order-history-table__sort--${
                currentSort.column === 'quantity'
                  ? currentSort.order
                  : 'inactive'
              }`"
            >
              Quantity
              <SoonaIcon name="arrow-up" size="small" />
            </span>
          </th>
          <th
            tabindex="0"
            @click="() => handleSort('pro_service_hours')"
            @keydown="e => handleKeydown(e, 'pro_service_hours')"
          >
            <span
              class="contract-order-history-table__sort"
              :class="`contract-order-history-table__sort--${
                currentSort.column === 'pro_service_hours'
                  ? currentSort.order
                  : 'inactive'
              }`"
            >
              Hours
              <SoonaIcon name="arrow-up" size="small" />
            </span>
          </th>
          <th
            tabindex="0"
            @click="() => handleSort('booking_id')"
            @keydown="e => handleKeydown(e, 'booking_id')"
          >
            <span
              class="contract-order-history-table__sort"
              :class="`contract-order-history-table__sort--${
                currentSort.column === 'booking_id'
                  ? currentSort.order
                  : 'inactive'
              }`"
            >
              Booking ID
              <SoonaIcon name="arrow-up" size="small" />
            </span>
          </th>
          <th
            tabindex="0"
            @click="() => handleSort('amount')"
            @keydown="e => handleKeydown(e, 'amount')"
          >
            <span
              class="contract-order-history-table__sort"
              :class="`contract-order-history-table__sort--${
                currentSort.column === 'amount' ? currentSort.order : 'inactive'
              }`"
            >
              Amount
              <SoonaIcon name="arrow-up" size="small" />
            </span>
          </th>
          <th
            tabindex="0"
            @click="() => handleSort('balance')"
            @keydown="e => handleKeydown(e, 'balance')"
          >
            <span
              class="contract-order-history-table__sort"
              :class="`contract-order-history-table__sort--${
                currentSort.column === 'balance'
                  ? currentSort.order
                  : 'inactive'
              }`"
            >
              Balance
              <SoonaIcon name="arrow-up" size="small" />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="transaction in transactions"
          :key="transaction.id"
          class="u-small--regular contract-order-history-table__row"
          :class="`contract-order-history-table__row--${transaction.type}`"
          tabindex="0"
          @click="() => handleRowAction(transaction.id)"
          @keydown="e => handleRowAction(transaction.id, e)"
        >
          <td>{{ dayjs(transaction.date).format('MMM D, YYYY') }}</td>
          <td>{{ transaction.charged_by }}</td>
          <td>
            <span
              class="u-badge--small contract-order-history-table__tag"
              :class="`contract-order-history-table__tag--${transaction.type}`"
            >
              {{ transaction.type.replace(/-/g, ' ') }}
            </span>
          </td>
          <td>{{ transaction.service ?? '--' }}</td>
          <td>{{ transaction.quantity ?? '--' }}</td>
          <td>
            {{ transaction.pro_service_hours ?? '--' }}
          </td>
          <td>#{{ transaction.booking_id }}</td>
          <td
            class="u-label--heavy"
            :class="`contract-order-history-table__amount-${transaction.type}`"
          >
            {{
              (transaction.type === 'invoice-paid' ? '+' : '-') +
              toCurrency(transaction.amount)
            }}
          </td>
          <td>{{ toCurrency(transaction.balance) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.contract-order-history-table {
  overflow-x: auto;

  table {
    white-space: nowrap;
    width: 100%;
  }

  tr {
    border-bottom: 0.0625rem solid variables.$gray-30;
    padding: 0.75rem 0.5rem;
  }

  tbody {
    tr:last-child {
      border-bottom: none;
    }
  }

  th {
    @include variables_fonts.u-small--heavy;

    color: variables.$gray-60;
    cursor: pointer;
    padding: 0.5rem;
    transition: background-color 0.2s;

    &:hover {
      background-color: variables.$gray-05;
    }

    &:focus-visible {
      outline: 0.125rem solid variables.$periwink-blue-60;
      outline-offset: -0.125rem;
    }
  }

  td {
    padding: 1rem 0.5rem;
  }

  &__row {
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: variables.$periwink-blue-10;
    }

    &:focus-visible {
      outline: 0.125rem solid variables.$periwink-blue-60;
      outline-offset: -0.125rem;
    }
  }

  &__tag {
    border-radius: 0.1875rem;
    padding: 0.25rem 0.375rem;

    &--invoice-paid {
      background-color: variables.$avo-toast-30;
    }

    &--other {
      background-color: variables.$tangerine-20;
      color: variables.$tangerine-80;
    }

    &--photo,
    &--video {
      background-color: variables.$bubbletape-pink-20;
    }

    &--ugc {
      background-color: variables.$green-apple-20;
    }
  }

  &__amount-invoice-paid {
    color: variables.$avo-toast-60;
  }

  &__sort {
    align-items: center;
    display: flex;
    gap: 0.25rem;

    &--desc {
      svg {
        transform: rotate(180deg);
      }
    }

    &--inactive {
      svg {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
</style>
