<script setup>
import { computed, ref } from 'vue';
import dayjs from 'dayjs';
import ContractOrderHistoryTable from '@/components/user/anytime/contracts/ContractOrderHistoryTable.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { mockTransactions } from './contract-dummy-data';

defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
});

const transactions = ref(mockTransactions);

const isEmpty = computed(() => transactions.value?.length < 1);

// payments_todo: move sorting to back end once data is hooked up
const handleSort = ({ column, order }) => {
  transactions.value = transactions.value.sort((a, b) => {
    if (column === 'date') {
      const dateA = dayjs(a[column]).unix();
      const dateB = dayjs(b[column]).unix();

      if (order === 'asc') return dateA - dateB;

      return dateB - dateA;
    } else {
      const valueA = a[column];
      const valueB = b[column];

      if (order === 'asc') return valueA - valueB || valueA < valueB;

      return valueB - valueA || valueA > valueB;
    }
  });
};

// payments_todo: make this do something
const handleDownload = () => {
  console.log('download');
};
</script>

<template>
  <article class="contract-order-history">
    <div class="contract-order-history__header">
      <h2 class="u-subheader--heavy">Order history</h2>
      <SoonaButton
        v-if="!isEmpty"
        size="medium"
        variation="secondary-gray"
        @on-click="handleDownload"
      >
        Download all
        <SoonaIcon name="arrow-down-to-bracket" />
      </SoonaButton>
    </div>
    <ContractOrderHistoryTable
      v-if="!isEmpty"
      :account-id="accountId"
      :transactions="transactions"
      @on-sort="handleSort"
    />
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.contract-order-history {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
</style>
