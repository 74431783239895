<script setup>
import { computed, ref } from 'vue';

import { useFlag } from '@/composables/useFlag';
import { useCapability } from '@/composables/useCapability';
import { useBulkDeleteAlbumCollectionDigitalAssets } from '@/queries/album_collection_digital_assets/useBulkDeleteAlbumCollectionDigitalAssets';

import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import AlbumPicker from '@/components/user/anytime/album_picker/AlbumPicker.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  addToAlbum: {
    type: Function,
    required: true,
  },
  addToFavorites: {
    type: Function,
    required: true,
  },
  selectedAssets: {
    type: Array,
    required: true,
  },
  albumCollectionId: {
    required: false,
    type: [Number, String],
  },
  showRemoveFromAlbum: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(['cancel']);

const accountId = computed(() => props.accountId);
const selectedAssets = computed(() => props.selectedAssets);
const albumCollectionId = computed(() => props.albumCollectionId);

const bulkRemoveFromAlbumFlag = useFlag('pegasus_bulk_remove_from_album');

const { hasCapability: hasFavoriteCapability } = useCapability({
  capability: 'favorite_gallery',
  subjectType: 'account',
  subjectId: accountId,
});

const selectedAreAllFavs = computed(() =>
  selectedAssets.value.every(a => a?.favorites_collection_digital_asset?.id)
);

const displayAlbumPicker = ref(false);

const { mutate: bulkDeleteAlbumCollectionDigitalAssets } =
  useBulkDeleteAlbumCollectionDigitalAssets();

const bulkRemoveFromAlbum = () => {
  bulkDeleteAlbumCollectionDigitalAssets(
    {
      collectionId: albumCollectionId.value,
      accountId: accountId.value,
      body: {
        digital_asset_ids: selectedAssets.value.map(a => a.id),
      },
    },
    {
      onSuccess: () => {
        emit('cancel');
      },
    }
  );
};
</script>

<template>
  <SoonaDropdownMenu
    size="large"
    variation="icon-transparent"
    :display-caret="false"
  >
    <template #trigger-content>
      <SoonaIcon name="dots-vertical" />
      <span class="u-visually-hidden">more actions</span>
    </template>

    <template #default="{ keydown, mouseover, clickCapture }">
      <SoonaDropdownMenuItem
        v-if="hasFavoriteCapability && !selectedAreAllFavs"
      >
        <button
          role="menuitem"
          class="organize-menu__item"
          @click="addToFavorites"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaIcon name="heart" />
          add to favorites
        </button>
      </SoonaDropdownMenuItem>

      <SoonaDropdownMenuItem>
        <button
          role="menuitem"
          class="organize-menu__item"
          @click="displayAlbumPicker = true"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaIcon name="album" />
          add to album
        </button>
      </SoonaDropdownMenuItem>

      <SoonaDropdownMenuItem
        v-if="
          albumCollectionId && showRemoveFromAlbum && bulkRemoveFromAlbumFlag
        "
      >
        <button
          role="menuitem"
          class="organize-menu__item"
          :disabled="!isMyAccount"
          @click="bulkRemoveFromAlbum"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaIcon name="trash" />
          remove from album
        </button>
      </SoonaDropdownMenuItem>
    </template>
  </SoonaDropdownMenu>

  <AlbumPicker
    v-if="displayAlbumPicker"
    :account-id="accountId"
    :selected-digital-assets="selectedAssets"
    @cancel="displayAlbumPicker = false"
    @select="addToAlbum"
  />
</template>

<style lang="scss" scoped>
.organize-menu {
  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
