<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useMe } from '@/composables/user/useMe';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useUpdateFavoritesCollection } from '@/queries/favorites_collection/useUpdateFavoritesCollection';
import { useBulkCreateAlbumCollectionDigitalAsset } from '@/queries/album_collection_digital_assets/useBulkCreateAlbumCollectionDigitalAsset';

import OverflowMenu from './OverflowMenu.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import GalleryActionBarDownload from './GalleryActionBarDownload.vue';
import GalleryActionBarAddToBag from './GalleryActionBarAddToBag.vue';
import GalleryActionBarBatchEdit from './GalleryActionBarBatchEdit.vue';
import SoonaActionBar from '@/components/ui_library/SoonaActionBar.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import useActionBarMediaQuery from '@/components/user/anytime/reservation/gallery/digital_asset_multiselect/useActionBarMediaQuery';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
  showRemoveFromAlbum: {
    default: false,
    type: Boolean,
  },
  albumCollectionId: {
    required: false,
    type: [Number, String],
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close', 'remove-selected-asset']);

const router = useRouter();
const { currentUserId } = useMe();
const { actionBarIsMobile } = useActionBarMediaQuery();

const accountId = computed(() => props.accountId);
const selectedAssets = computed(() => props.selectedAssets);
const numberOfAssetsSelected = computed(() => selectedAssets.value.length);

// album
const {
  mutate: bulkCreateAlbumCollectionDigitalAssets,
  error: updateAlbumCollectionError,
} = useBulkCreateAlbumCollectionDigitalAsset();

const bulkAddToAlbum = collectionId => {
  bulkCreateAlbumCollectionDigitalAssets(
    {
      collectionId: collectionId,
      body: {
        digital_asset_ids: selectedAssets.value.map(a => a.id),
      },
    },
    {
      onSuccess: () => {
        emit('close');

        router.push({
          name: 'account-gallery',
          params: {
            accountId: accountId.value,
          },
          query: {
            collection_id: collectionId,
          },
        });
      },
    }
  );
};

// favorites
const { data: favoriteCollection } = useFavoritesCollection(accountId);
const favoritesCollectionId = computed(() => favoriteCollection.value?.id);

const {
  mutate: updateFavoritesCollectionAssets,
  error: updateFavoritesCollectionAssetsError,
} = useUpdateFavoritesCollection(accountId, favoritesCollectionId);

const bulkAddToFavorites = () => {
  const body = selectedAssets.value.reduce((acc, asset) => {
    if (!asset.favorites_collection_digital_asset?.id) {
      return [
        ...acc,
        {
          added_by_user_id: currentUserId.value,
          digital_asset_id: asset.id,
        },
      ];
    }

    return acc;
  }, []);

  updateFavoritesCollectionAssets(
    {
      favorites_collection_digital_assets_attributes: body,
    },
    {
      onSuccess: () => {
        emit('close');
      },
    }
  );
};

const hasError = computed(() => {
  return [
    updateAlbumCollectionError.value,
    updateFavoritesCollectionAssetsError.value,
  ].some(err => !!err);
});
</script>

<template>
  <SoonaActionBar
    class="multi-select-action-bar"
    :display="numberOfAssetsSelected > 0"
    :page-bounding-rect-left="pageBoundingRectLeft"
    :page-bounding-rect-width="pageBoundingRectWidth"
  >
    <template #close-button>
      <SoonaTooltip v-if="!actionBarIsMobile" placement="top">
        <template
          #default="{
            setRef,
            mouseenter,
            focus,
            mouseleave,
            blur,
            ariaDescribedby,
          }"
        >
          <SoonaButton
            :ref="setRef"
            variation="icon-transparent"
            :aria-describedby="ariaDescribedby"
            @blur="blur"
            @focus="focus"
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
            @on-click="$emit('close')"
          >
            <SoonaIcon name="xmark" />
            <span class="u-visually-hidden">cancel</span>
          </SoonaButton>
        </template>

        <template #tooltip-content>cancel</template>
      </SoonaTooltip>
    </template>

    <template #content>
      <SoonaError
        v-if="hasError"
        no-margin
        class="multi-select-action-bar__error"
      >
        something went wrong. please try again.
      </SoonaError>

      <p class="u-label--heavy multi-select-action-bar__selected-assets">
        {{ numberOfAssetsSelected }} asset{{
          numberOfAssetsSelected > 1 ? 's' : ''
        }}
        selected
      </p>
    </template>

    <template #actions>
      <OverflowMenu
        :account-id="accountId"
        :add-to-album="bulkAddToAlbum"
        :add-to-favorites="bulkAddToFavorites"
        :is-icon-button="actionBarIsMobile"
        :selected-assets="selectedAssets"
        :album-collection-id="albumCollectionId"
        :show-remove-from-album="showRemoveFromAlbum"
        @cancel="$emit('close')"
      />

      <GalleryActionBarDownload
        :account-id="accountId"
        :selected-assets="selectedAssets"
      />

      <GalleryActionBarBatchEdit
        :account-id="accountId"
        :selected-assets="selectedAssets"
        @close="emit('close')"
        @remove-selected-asset="asset => emit('remove-selected-asset', asset)"
      />

      <GalleryActionBarAddToBag
        :account-id="accountId"
        :selected-assets="selectedAssets"
        @close="emit('close')"
      />
    </template>
  </SoonaActionBar>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.multi-select-action-bar {
  &__error {
    margin-bottom: 1rem;
  }

  &__selected-assets {
    margin-left: auto;
  }
}
</style>
