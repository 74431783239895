<script setup>
import { computed } from 'vue';
import { useContract } from '@/composables/contracts/useContract';
import dayjs from 'dayjs';
import ContractSummaryField from '@/components/user/anytime/contracts/ContractSummaryField.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  contractId: {
    required: true,
    type: [Number, String],
  },
  isSoonaStaff: {
    default: false,
    type: Boolean,
  },
});
const accountId = computed(() => props.accountId);
const contractId = computed(() => props.contractId);

const {
  contract: activeContract,
  contractTotalDisplay,
  accountManager,
  enterpriseContractItem,
  discount,
  fastPassContractItem,
  gifUnitRateDisplay,
  photoUnitRateDisplay,
  ugcUnitRateDisplay,
  videoUnitRateDisplay,
} = useContract({ accountId, contractId });

const accountManagerCopy = computed(() => {
  if (props.isSoonaStaff) return 'Managed by';

  return 'Your account manager';
});

const contractDuration = computed(() => {
  if (!activeContract.value) return '--';

  const formattedStartDate = dayjs(activeContract.value?.start_date).format(
    'MM/DD/YYYY'
  );

  const formattedEndDate = dayjs(activeContract.value?.end_date).format(
    'MM/DD/YYYY'
  );

  return `${formattedStartDate} - ${formattedEndDate}`;
});
</script>

<template>
  <article class="contract-summary">
    <div class="contract-summary__account-manager">
      <SoonaAvatar
        :name="accountManager?.name"
        size="2.5rem"
        type="account"
        :url="accountManager?.avatar_url"
      />
      <div>
        <p class="u-badge--small">{{ accountManagerCopy }}</p>
        <p class="u-body--heavy">{{ accountManager?.name }}</p>
      </div>
    </div>
    <div class="contract-summary__summary-content">
      <p class="u-subheader--heavy">Contract ID: {{ activeContract?.id }}</p>
      <p class="u-label--heavy contract-summary__subtext">
        Duration: {{ contractDuration }}
      </p>
      <div class="contract-summary__summary">
        <div class="contract-summary__summary-row">
          <ContractSummaryField
            label="Total contract value"
            :value="contractTotalDisplay ?? '--'"
          />
          <ContractSummaryField
            label="Platform membership"
            :value="!!enterpriseContractItem ? 'Included' : 'Not included'"
          />
          <ContractSummaryField
            label="Fast Pass"
            :value="!!fastPassContractItem ? 'Included' : 'Not included'"
          />
          <ContractSummaryField
            label="Pro service discount rate"
            :value="`${discount?.percent_off ?? 0}%`"
          />
        </div>
        <div class="contract-summary__summary-row">
          <ContractSummaryField
            label="Photo unit rate"
            :value="!!photoUnitRateDisplay ? photoUnitRateDisplay : '--'"
          />
          <ContractSummaryField
            label="Video clip unit rate"
            :value="!!videoUnitRateDisplay ? videoUnitRateDisplay : '--'"
          />
          <ContractSummaryField
            label="GIF unit rate"
            :value="!!gifUnitRateDisplay ? gifUnitRateDisplay : '--'"
          />
          <ContractSummaryField
            label="UGC unit rate"
            :value="!!ugcUnitRateDisplay ? ugcUnitRateDisplay : '--'"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.contract-summary {
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  container: contract-summary / inline-size;
  margin: 1.5rem 0;
  overflow: hidden;

  &__account-manager {
    align-items: center;
    background-color: variables.$periwink-blue-20;
    display: flex;
    gap: 0.75rem;
    padding: 1rem 1.5rem;
  }

  &__summary-content {
    padding: 1.5rem;
  }

  &__subtext {
    color: variables.$gray-60;
    padding-bottom: 2rem;
  }

  &__summary {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__summary-row {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

@container contract-summary (min-width: 43rem) {
  .contract-summary__summary-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
